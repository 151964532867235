import React from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
function Employee(){
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const data = JSON.parse(localStorage.getItem('userData'))

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://influ.socialapi.stageprojects.xyz/project/${data._id}`);
        const list1 = await response.json();
        // console.log(list)
        setList(list1.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);
  console.log(list);
  const AddEmployee = () => {
    // Perform your login logic here
    // If login is successful, navigate to the dashboard
    navigate('/addemployee/');
  }; 
  const editEmployee = (id) => {
    console.log(id);
    // Perform your login logic here
    // If login is successful, navigate to the dashboard
    navigate(`/editemployee/${id}`);
  }; 
  const deleteEmployee = async (id) => {
    console.log(id);
    try {
      const response = await fetch(`https://influ.socialapi.stageprojects.xyz/project/delete/${id}`, {
        method: 'DELETE',
      });
  
      if (response.ok) {
        toast.success('Project Deleted Successfully');
      } else {
        // Handle the error response
        const errorMessage = await response.text(); // Get the error message from the response
        toast.error(`Failed to delete project: ${errorMessage}`);
      }
    } catch (error) {
      // Handle network or other unexpected errors
      console.error(error);
      toast.error('An error occurred while deleting the project.');
    }
  }
  
  return (
      <>
      <Sidebar />
      <div className="body-wrapper">
<Header />
      <div className="container-fluid">
        <div className="row">
          <div className="title-heading">
            <h1 className="heading">Projects</h1>
            <button id="add-employee" class="btn btn-primary" onClick={AddEmployee}>Add Project</button>
          </div>
            <div className="card w-100">
              <div className="card-body p-4">
                {/* <h5 className="card-title fw-semibold mb-4"></h5> */}
                <div className="table-responsive">
                  <table className="table text-nowrap mb-0 align-middle">
                    <thead className="text-dark fs-4">
                      <tr>
                        <th className="border-bottom-0">
                          <h6 className="fw-semibold mb-0">S.No.</h6>
                        </th>
                        <th className="border-bottom-0">
                          <h6 className="fw-semibold mb-0">Name</h6>
                        </th>
                        <th className="border-bottom-0">
                          <h6 className="fw-semibold mb-0">Type</h6>
                        </th>
                        <th className="border-bottom-0">
                          <h6 className="fw-semibold mb-0">Url</h6>
                        </th>
                        <th className="border-bottom-0">
                          <h6 className="fw-semibold mb-0">Banner Image</h6>
                        </th>
                        <th className="border-bottom-0">
                          <h6 className="fw-semibold mb-0">Action</h6>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {list.map((item, index) =>(
                      <tr>
                        <td className="border-bottom-0"><h6 className="fw-semibold mb-0">{index + 1}</h6></td>
                        <td className="border-bottom-0">
                            <h6 className="fw-semibold mb-1">{item.name}</h6>
                        </td>
                        <td className="border-bottom-0">
                          <div className="d-flex align-items-center gap-2">
                            <span className="badge bg-primary rounded-3 fw-semibold">{item.type}</span>
                          </div>
                        </td>
                        <td className="border-bottom-0">
                          <p className="mb-0 fw-normal">{item.url}</p>
                        </td>
                        <td className="border-bottom-0">
                         <img src={item.bannerimage} style={{width:'50px',height:'50px'}}/>
                        </td>
                        <td className="border-bottom-0">
                          <button id="add-employee" class="btn btn-primary" onClick={() => editEmployee(item._id)}>Edit</button>
                          <button id="add-employee" class="btn btn-primary" onClick={() => deleteEmployee(item._id)}>Delete</button>
                        </td>
                      </tr> 
                      ))}         
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        </>

);

}

export default Employee;