import React from "react";
import { useState, useEffect } from "react";
import axios from 'axios';
import "./LoginSignup.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { baseUrl } from "../../Constants/Constant";

function LoginForm({ handleFormSwitch}) {

  const navigate = useNavigate();
const [email, setEmail] = useState(''); 
const [password, setPassword] = useState(''); 
const handleSubmit = (e) => {
 e.preventDefault()
    axios.post(`${baseUrl}/influencer/login`,{
      email:email,
      password:password
    })
      .then((response) => {
        console.log(response.data)
      
          localStorage.setItem('token',response.data.token)
          localStorage.setItem('userData',JSON.stringify(response.data.data))
          navigate('/dashboard')
      
       
        // setData(response.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
 
  
};
  
  return (
    <>
    <form action="#" onSubmit={handleSubmit}>
      <div className="field">
        <input type="text" placeholder="Email Address" value={email} 
        onChange={(e) => setEmail(e.target.value)} required />
      </div>
      <div className="field">
        <input type="password" placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password} required />
      </div>
      <div className="pass-link">
        {/* <a href="#">Forgot password?</a> */}
      </div>
      <div className="field btn">
        <div className="btn-layer"></div>
        <input type="submit" value="Login"/>
      </div>
      <div className="signup-link">
        <Link>Not a member? Contact Us</Link>
        {/* <a href="#">Signup now</a> */}
      </div>
    </form>
</>

  );

}
export default LoginForm;