import React, { useState } from "react";
import "./LoginSignup.css";
import LoginForm from "./Loginform";
import SignupForm from "./SignupForm";

function LoginSignup() {
  const [isLoginForm, setIsLoginForm] = useState(true);

  const handleFormSwitch = () => {
    setIsLoginForm(!isLoginForm);
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   // Perform login or signup logic here
  // };

  return (
    <>
      <div className="wrapper">
        <div className="title-text">
          <div className={`title ${isLoginForm ? "login" : "signup"}`}>
            {isLoginForm ? "Login" : "Signup Form"}
          </div>
        </div>
        <div className="form-container">
         
          <div className="form-inner">
          
              <LoginForm
                handleFormSwitch={handleFormSwitch}
              />
           
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginSignup;
