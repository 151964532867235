import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import Header from "../Header";
import './Influencer.css';
import axios from "axios";
import { baseUrl } from "../../Constants/Constant";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

function Influencer(){
 const [user,setUser] = useState('')
  useEffect(()=>{
    const data = JSON.parse(localStorage.getItem('userData'))
    axios.get(`${baseUrl}/influencer/${data._id}`)
      .then((response) => {
        console.log(response.data)
        setUser(response.data.data)
       
       
        // setData(response.data);
      })
      .catch((error) => {
        toast.error(error);
      });
  },[])
    return (
        <>
      <Sidebar />
      <div className="body-wrapper">
<Header />
      <div className="container-fluid">
        <div className="row">
          <div className="title-heading">
            <h1 className="heading">Influencer</h1>
          </div>
         {user == ''?null:
            <div class="student-profile py-4">
  <div class="container">
    <div class="row">
      <div class="col-lg-4">
        <div class="card shadow-sm">
          <div class="card-header bg-transparent text-center">
          <div><Link to="/editinfluencer">edit</Link></div>
            <img class="profile_img" src={user.image} alt="student dp"/>
            <h3>{user.influencername}</h3>
          </div>
          <div class="card-body">
            <p class="mb-0"><strong class="pr-1">USERNAME :</strong>{user.userName}</p>
            <p class="mb-0"><strong class="pr-1">Followers:</strong>{user.followers}</p>
            <p class="mb-0"><strong class="pr-1">Location:</strong>{user.location}</p>
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="card shadow-sm">
          <div class="card-header bg-transparent border-0">
            <h3 class="mb-0"><i class="far fa-clone pr-1"></i>Additional Information</h3>
          </div>
          <div class="card-body pt-0">
            <table class="table table-bordered">
              <tr>
                <th width="30%">Average Reel Play</th>
                <td width="2%">:</td>
                <td>{user.additional_info.avgReelPlay}</td>
              </tr>
              <tr>
                <th width="30%">Happy Clients</th>
                <td width="2%">:</td>
                <td>{user.additional_info.happyClients}</td>
              </tr>
              <tr>
                <th width="30%">Awards Win</th>
                <td width="2%">:</td>
                <td>{user.additional_info.awardsWin}</td>
              </tr>
              <tr>
                <th width="30%">Cup Of Coffee</th>
                <td width="2%">:</td>
                <td>{user.additional_info.cupofcoffee}</td>
              </tr>
            
            </table>
          </div>
        </div>
          
        <div class="card shadow-sm">
          <div class="card-header bg-transparent border-0">
            <h3 class="mb-0"><i class="far fa-clone pr-1"></i>Other Information</h3>
          </div>
          <div class="card-body pt-0">
              <p>{user.description}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
}
          </div>
        </div>
        </div>
        </>
    )
}
export default Influencer;