import React, { useEffect, useState,useRef } from 'react';
import { toast } from 'react-toastify';

import Header from "../Header";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import AWS from 'aws-sdk';
import Sidebar from '../Sidebar';
import axios from 'axios';
import { awsKeyId, awsSecretKey, baseUrl } from '../../Constants/Constant';
AWS.config.update({
    accessKeyId: awsKeyId,
    secretAccessKey: awsSecretKey,
    region: 'ap-southeast-1',
    signatureVersion: 'v4'
  });

  const s3 = new AWS.S3();


function EditInfluencer(){
  const navigate = useNavigate();
  const [profile,setProfile] = useState('')
const name = useRef(null);
const username = useRef(null);
const email = useRef(null);
const short_description = useRef(null);
const description = useRef(null);
const avgreelplay = useRef(null);
const happyclient = useRef(null);
const awardwin = useRef(null);
const cupofcoffee = useRef(null);
const title1 = useRef(null);
const description1 = useRef(null);
const title2 = useRef(null);
const description2 = useRef(null);
const title3 = useRef(null);
const description3 = useRef(null);
const title4 = useRef(null);
const facebook = useRef(null);
const instagram = useRef(null);
const twitter = useRef(null);
const pinterest = useRef(null);
const youtube = useRef(null);
const description4 = useRef(null);
const logo = useRef(null);

const data = JSON.parse(localStorage.getItem('userData'))
const image = useRef(null);

    // Send form data to the backend server
    useEffect(() => {
     
       axios.get(`${baseUrl}/influencer/${data._id}`)
        .then((response) => {
          // console.log(response.data)
          setProfile(response.data.data)
          console.log(profile,'sdgsgfg')
         
          // setData(response.data);
        })
        .catch((error) => {
          toast.error(error);
        });
       
    }, []);
const handleBlogSubmit = async(e) => {
let imagedata 
let logoImage
    e.preventDefault();
   
    //Profile Image
    if(image.current.value!=''){
      const params = {
        Bucket: 'clwbuckets',
        Key: `${Date.now()}.${image.current.files[0].name}`,
        Body: image.current.files[0],
        ACL: 'public-read'
      };
      const { Location } = await s3.upload(params).promise();
      imagedata = Location
     
    }
    else{
        imagedata = profile.image
      
    }

    //Logo Image
    if(logo.current.value!=''){
      const logoData = {
        Bucket: 'clwbuckets',
        Key: `${Date.now()}.${logo.current.files[0].name}`,
        Body: logo.current.files[0],
        ACL: 'public-read'
      };
      const { Location } = await s3.upload(logoData).promise();
      logoImage = Location
     console.log(logoImage,'if')
    }
    else{
        logoImage = profile.logo
        console.log(logoImage,'else')
    }

    let userdata ={
      "influencername":name.current.value,
      "image":imagedata,
      "userName":username.current.value,
      "short_description":short_description.current.value,
      "description":description.current.value,
      "logo":logoImage,
      "socialLinks":{
          "facebook":facebook.current.value,
          "instagram":instagram.current.value,
          "twitter":twitter.current.value,
          "youtube":youtube.current.value,
          "pinterest":pinterest.current.value,
      },
      "additional_info":{
        "avgReelPlay":avgreelplay.current.value,
        "happyClients":happyclient.current.value,
        "awardsWin":awardwin.current.value,
        "cupofcoffee":cupofcoffee.current.value,
      },
      "why_choose_me":[
        {
          "title":title1.current.value,
          "description":description1.current.value
        },
        {
          "title":title2.current.value,
          "description":description2.current.value
        },
        {
          "title":title3.current.value,
          "description":description3.current.value
        },
        {
          "title":title4.current.value,
          "description":description4.current.value
        },
      ]
    }
   
   
      axios.put(`${baseUrl}/influencer/edit/${data._id}`,userdata)
      .then((response) => {
        // console.log(response.data,'shgsgggfj')
      toast.success('Update Successfully')
      navigate('/profile')
       
        // setData(response.data);
      })
      .catch((error) => {
        console.log(error)
        // toast.error(error);
      });
  
    };
    return(
        <>
        <Sidebar />
      <div className="body-wrapper">
<Header />
            <div className="container-fluid">
          <div className="card">
            {profile == ''?(null):
            <div className="card-body">
              <h5 className="card-title fw-semibold mb-4">Edit Profile</h5>
              <div className="card">

                <div className="card-body">
                <h6 className="card-title fw-semibold mb-4">Basic Info</h6>

                  <form action="#" onSubmit={handleBlogSubmit}>
                  <div className="mb-3">
                    <img style={{width:'50px', height:'50px',marginBottom:'10px'}} src={profile.logo}/>
                      <label for="logo" className="form-label">Logo</label>
                      <input type="file" name="logo" ref={logo} className="form-control" id="logo" 
                      
                        aria-describedby="emailHelp"/>
                    </div>
                  <div className="mb-3">
                    <img style={{width:'50px', height:'50px',marginBottom:'10px'}} src={profile.image}/>
                      <label for="image" className="form-label">Profile Image</label>
                      <input type="file" name="image" ref={image} className="form-control" id="image" 
                      
                        aria-describedby="emailHelp"/>
                    </div>
                  <div className="mb-3">
                      <label for="name" className="form-label">Name</label>
                      <input type="text" name="name" className="form-control"
                        ref={name}
                       defaultValue={profile.influencername}          
                          id="name"/>
                    </div>
                    <div className="mb-3">
                      <label for="username" className="form-label">User Name</label>
                      <input type="text" name="username" className="form-control"
                        ref={username}
                       defaultValue={profile.userName}          
                          id="username"/>
                    </div>
                    <div className="mb-3">
                      <label for="email" className="form-label">Email Id</label>
                      <input type="text" name="email" className="form-control"
                        ref={email}
                       defaultValue={profile.email}   
                       disabled       
                          id="email"/>
                    </div>
                    <div className="mb-3">
                      <label for="short_description" className="form-label">Short Description</label>
                      <textarea type="textarea" row={5} col={5} name="short_description" className="form-control" id="short_description" 
                       ref={short_description}
                       defaultValue={profile.short_description}
                        aria-describedby="emailHelp">
                            </textarea>
                    </div>
                    <div className="mb-3">
                      <label for="description" className="form-label">Description</label>
                      <textarea type="textarea" style={{height:'100px'}} row={10} col={10} name="description" className="form-control" id="description" 
                       ref={description}
                       defaultValue={profile.description}
                        aria-describedby="emailHelp">
                            </textarea>
                    </div>
                  
                  
                       <h6 className="card-title fw-semibold mb-4">Additional Info</h6>
                      <div className="mb-3">
                      <label for="username" className="form-label">Average Reel Play</label>
                      <input type="text" name="username" className="form-control"
                        ref={avgreelplay}
                       defaultValue={profile.additional_info.avgReelPlay}          
                          id="username"/>
                    </div>
                    <div className="mb-3">
                      <label for="username" className="form-label">Happy Clients</label>
                      
                      <input type="text" name="username" className="form-control"
                        ref={happyclient}
                       defaultValue={profile.additional_info.happyClients}          
                          id="username"/>
                          
                    </div>
                    <div className="mb-3">
                      <label for="username" className="form-label">Awards Win</label>
                      
                      <input type="text" name="username" className="form-control"
                        ref={awardwin}
                       defaultValue={profile.additional_info.awardsWin}          
                          id="username"/>
                          
                    </div>
                    <div className="mb-3">
                      <label for="username" className="form-label">Cup Of Coffee</label>
                      <input type="text" name="username" className="form-control"
                        ref={cupofcoffee}
                       defaultValue={profile.additional_info.cupofcoffee}          
                          id="username"/>
                    </div>
                    <h6 className="card-title fw-semibold mb-4">Why Choose Me</h6>
                    {profile.why_choose_me.length == 0 ? null:<>
                    <div className="mb-3">
                      <label for="username" className="form-label">Title</label>
                      <input type="text" name="username" className="form-control"
                        ref={title1}
                       defaultValue={profile.why_choose_me[0].title}          
                          id="username"/>
                    </div>
                    <div className="mb-3">
                      <label for="username" className="form-label">Description</label>
                      <input type="text" name="username" className="form-control"
                        ref={description1}
                       defaultValue={profile.why_choose_me[0].description}          
                          id="username"/>
                    </div>

                    <div className="mb-3">
                      <label for="username" className="form-label">Title</label>
                      <input type="text" name="username" className="form-control"
                        ref={title2}
                       defaultValue={profile.why_choose_me[1].title}          
                          id="username"/>
                    </div>
                    <div className="mb-3">
                      <label for="username" className="form-label">Description</label>
                      <input type="text" name="username" className="form-control"
                        ref={description2}
                       defaultValue={profile.why_choose_me[1].description}          
                          id="username"/>
                    </div>
                    <div className="mb-3">
                      <label for="username" className="form-label">Title</label>
                      <input type="text" name="username" className="form-control"
                        ref={title3}
                       defaultValue={profile.why_choose_me[2].title}          
                          id="username"/>
                    </div>
                    <div className="mb-3">
                      <label for="username" className="form-label">Description</label>
                      <input type="text" name="username" className="form-control"
                        ref={description3}
                       defaultValue={profile.why_choose_me[2].description}          
                          id="username"/>
                    </div>

                    <div className="mb-3">
                      <label for="username" className="form-label">Title</label>
                      <input type="text" name="username" className="form-control"
                        ref={title4}
                       defaultValue={profile.why_choose_me[3].title}          
                          id="username"/>
                    </div>
                    <div className="mb-3">
                      <label for="username" className="form-label">Description</label>
                      <input type="text" name="username" className="form-control"
                        ref={description4}
                       defaultValue={profile.why_choose_me[3].description}          
                          id="username"/>
                    </div>
                    </>}
                    <h6 className="card-title fw-semibold mb-4">Social Links</h6>

                    <div className="mb-3">
                      <label for="facebook" className="form-label">Facebook</label>
                      <input type="text" name="facebook" className="form-control"
                        ref={facebook}
                       defaultValue={profile.socialLinks.facebook}          
                          id="facebook"/>
                    </div>
                    <div className="mb-3">
                      <label for="instagram" className="form-label">Instagram</label>
                      <input type="text" name="instagram" className="form-control"
                        ref={instagram}
                       defaultValue={profile.socialLinks.instagram}          
                          id="instagram"/>
                    </div>
                    <div className="mb-3">
                      <label for="twitter" className="form-label">Twitter</label>
                      <input type="text" name="twitter" className="form-control"
                        ref={twitter}
                       defaultValue={profile.socialLinks.twitter}          
                          id="twitter"/>
                    </div>
                    <div className="mb-3">
                      <label for="pinterest" className="form-label">Pinterest</label>
                      <input type="text" name="pinterest" className="form-control"
                        ref={pinterest}
                       defaultValue={profile.socialLinks.pinterest}          
                          id="pinterest"/>
                    </div>
                    <div className="mb-3">
                      <label for="youtube" className="form-label">Youtube</label>
                      <input type="text" name="youtube" className="form-control"
                        ref={youtube}
                       defaultValue={profile.socialLinks.youtube}          
                          id="youtube"/>
                    </div>
                    <button type="submit" className="btn btn-primary">Submit</button>
                  </form>
                </div>
              </div>
            </div>
}
          </div>
        </div>
        </div>
        </>
    );
}
export default EditInfluencer;