import React, { useState } from "react";
import "./LoginSignup.css";


function SignupForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const handleSignup = () => {
    // Perform your login logic here
    // If login is successful, navigate to the dashboard
    window.location.reload();
    };
  const handleSubmit = async (e) => {
    try {
      // Send form data to the backend server
      const response = await fetch("http://localhost:8000/api/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          password,
        }),
      });
      // Handle the response from the server
      // const data = await response.json();
  
      console.log(response); // You can customize this based on your server response
    } catch (error) {
      console.error(error);
    }
  };
    return (
    <form action="#" onSubmit={handleSubmit}>
      <div className="field">
        <input
          type="text"
          placeholder="Name"
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="field">
        <input
          type="text"
          placeholder="Email Address"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="field">
        <input
          type="password"
          placeholder="Password"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div className="field">
        <input
          type="password"
          placeholder="Confirm password"
          required
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </div>
      <div className="field btn">
        <div className="btn-layer"></div>
        <input type="submit" value="Signup" onClick={handleSignup}/>
      </div>
    </form>
  );
}

export default SignupForm;
