import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import { toast } from 'react-toastify';

import Header from "../Header";
import { Link, useNavigate } from "react-router-dom";
import { IAM } from "aws-sdk";
import Influencer from "./Influencer";


function InfluencerList() {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const fetchData = async () => {
    try {
      const response = await fetch('https://influ.socialapi.stageprojects.xyz/influencer/listing?filter={}');
      const list1 = await response.json();
      // console.log(list)
      setList(list1);
      // console.log(list)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://influ.socialapi.stageprojects.xyz/influencer/listing?filter={}');
        const list1 = await response.json();
        console.log(list1)
        setList(list1);
        // console.log(list,'fgfgfgfgg')
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleEdit = (id) => {
    navigate(`/editblog/${id}`)
  }
  const handleDelete = async (id) => {
    try {
      const response = await fetch(`https://influ.socialapi.stageprojects.xyz/influencer/delete/${id}`,{
        method: "DELETE",
        headers: {
          "Content-Type": "application/json"
        }});
      const list1 = await response.json();
      // console.log(list)
      fetchData()
      toast.success("Influencer Deleted Successfully.");
      // console.log(list)
    } catch (error) {
      toast.error('Error Delete Influencer');
    }
  }
  return (
    <>
      <Sidebar />
      <div className="body-wrapper">
        <Header />
        {list == null ? null :
        <div className="container-fluid">
          <div className="row">
            <div className="title-heading">
              <h1 className="heading">Influencers</h1>
              <Link to="/addinfluencer" className="btn btn-primary">Add Influencer</Link>
            </div>
            <div className="card w-100">
              <div className="card-body p-4">
                <h5 className="card-title fw-semibold mb-4">All Influencer</h5>
                <div className="table-responsive">
                  <table className="table text-nowrap mb-0 align-middle">
                    <thead className="text-dark fs-4">
                      <tr>
                        <th className="border-bottom-0">
                          <h6 className="fw-semibold mb-0">S.No</h6>
                        </th>
                        <th className="border-bottom-0">
                          <h6 className="fw-semibold mb-0">Image</h6>
                        </th>
                        <th className="border-bottom-0">
                          <h6 className="fw-semibold mb-0">Name</h6>
                        </th>
                        <th className="border-bottom-0">
                          <h6 className="fw-semibold mb-0">Email</h6>
                        </th>
                        <th className="border-bottom-0">
                          <h6 className="fw-semibold mb-0">Action</h6>
                        </th>

                      </tr>
                    </thead>
                    <tbody>
                      {list.map((item, index) => (
                        <tr>
                          <td className="border-bottom-0"><h6 className="fw-semibold mb-0">{index + 1}</h6></td>
                          <td className="border-bottom-0">
                            <img style={{ width: '50px', height: '50px' }} className="fw-normal" src={item.image} />
                          </td>
                          <td className="border-bottom-0">
                            <p className="mb-0 fw-normal">{item.influencername}</p>
                          </td>
                          <td className="border-bottom-0">

                            <p className="mb-0 fw-normal">{item.email}</p>

                          </td>
                          <td className="border-bottom-0" style={{ float: 'left' }}>

                            <button id="add-employee" class="btn btn-primary" onClick={() => handleDelete(item._id)} >Delete</button>
                          </td>
                        </tr>
                      ))}

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
}
      </div>
    </>
  );
}

export default InfluencerList;