import React from "react";
import { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
function Dashboard() {
  const [data, setData] = useState([]);

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem('userData'));
    console.log(savedData,'ss')
    if (savedData) {
      setData(savedData);
    }
  }, []);
    return (
    <>
          <Sidebar />
        <div className="body-wrapper">
<Header />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-6 col-xl-3">
            <h1 className="heading">Welcome Back! {data.influencername}</h1>
          </div>
        </div>
        </div>
        </div>
        </>
    );
}
export default Dashboard;