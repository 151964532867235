import React, { useRef, useState } from 'react';
import Sidebar from "../Sidebar";
import Header from "../Header";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import AWS from 'aws-sdk';
import { baseUrl } from '../../Constants/Constant';
import axios from 'axios';


function AddInfluencer (){
   
  const navigate = useNavigate();
const name = useRef(null);
const email = useRef(null);
const password = useRef(null);

const handleSubmit = async (e) => {
    e.preventDefault();
    // alert(title.current.value)
    let userdata ={
        "influencername":name.current.value,
        "email":email.current.value,
        "password":password.current.value,
       
      }
     
     
        axios.post(`${baseUrl}/influencer/register`,userdata)
        .then((response) => {
        //   console.log(response.data,'shgsgggfj')
        toast.success('Influencer Added Successfully')
        navigate('/influencerlist')
         
          // setData(response.data);
        })
        .catch((error) => {
        //   console.log(error)
          toast.error(error);
        });
    
}
   
    return(
        <>
        <Sidebar />
      <div className="body-wrapper">
<Header />
            <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title fw-semibold mb-4">Add New Influencer</h5>
              <div className="card">
                <div className="card-body">
                    <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label for="name" className="form-label">Name</label>
                      <input type="text" name="name" className="form-control"
                        ref={name}
                                                
                          id="name"/>
                    </div>
                    <div className="mb-3">
                      <label for="email" className="form-label">Email Id</label>
                      <input type="text" name="email" className="form-control" id="email" 
                       ref={email}
                        aria-describedby="emailHelp"/>
                    </div>
                    <div className="mb-3">
                      <label for="password" className="form-label">Password</label>
                      <input type="password" name="password" className="form-control" id="password" 
                       ref={password}
                        aria-describedby="emailHelp"/>
                    </div>
                  
                   
                    <button type="submit" className="btn btn-primary">Submit</button>
                    </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        </>
    );
}
export default AddInfluencer;