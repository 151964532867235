import React, { useEffect, useRef, useState } from 'react';
import Sidebar from "../Sidebar";
import Header from "../Header";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { awsKeyId, awsSecretKey, baseUrl } from '../../Constants/Constant';
import AWS from 'aws-sdk';


AWS.config.update({
  accessKeyId: awsKeyId,
  secretAccessKey: awsSecretKey,
  region: 'ap-southeast-1',
  signatureVersion: 'v4'
});
const s3 = new AWS.S3();
function EditEmployee (){
  const navigate = useNavigate();
// const[name, setName] = useState('');
// const[url, setUrl] = useState('');
// const[type, setType] = useState('');
const { id } = useParams();
const[project, setProject] = useState('');
// const[bannerimage, setBannerImage] = useState('');
const name = useRef(null);
const url = useRef(null);
const type = useRef(null);
const bannerimage = useRef(null);
const data = JSON.parse(localStorage.getItem('userData'))

    // Send form data to the backend server
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(`https://influ.socialapi.stageprojects.xyz/project/getbyprojectId/${id}`);
          const list1 = await response.json();
          // console.log(list)
          setProject(list1.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    
      fetchData();
    }, []);
const handleEmployeeSubmit = async(e) => {

  let imagedata 
  e.preventDefault();
  console.log(bannerimage,'bg')
 
  if(bannerimage.current.value!=''){
    const params = {
      Bucket: 'clwbuckets',
      Key: `${Date.now()}.${bannerimage.current.files[0].name}`,
      Body: bannerimage.current.files[0],
      ACL: 'public-read'
    };
    const { Location } = await s3.upload(params).promise();
    imagedata = Location
    console.log('if')
  }
  else{
      imagedata = project.bannerimage
      console.log(imagedata,'dsfdgfgfbbgbgbgbgbggbgbg')
  }
  let userdata ={
    "name":name.current.value,
    "url":url.current.value,
    "type":type.current.value,
    "bannerimage":imagedata,
    "influencerId":data._id,
    
  }
 
 
    axios.put(`${baseUrl}/project/edit/${id}`,userdata)
    .then((response) => {
      console.log(response.data,'shgsgggfj')
    toast.success('Update Successfully')
    navigate('/project')
     
      // setData(response.data);
    })
    .catch((error) => {
      console.log(error)
      // toast.error(error);
    });

    };
    return(
        <>
        <Sidebar />
      <div className="body-wrapper">
<Header />
            <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title fw-semibold mb-4">Edit Project</h5>
              <div className="card">
                <div className="card-body">
                  <form action="#" onSubmit={handleEmployeeSubmit}>
                    <div className="mb-3">
                      <label for="exampleInputPassword1" className="form-label">Name</label>
                      <input type="name" name="name" className="form-control"
                      ref={name}
                        defaultValue={project.name}                            
                          id="exampleInputPassword1"/>
                    </div>
                    <div className="mb-3">
                      <label for="exampleInputEmail1" className="form-label">Url</label>
                      <input type="text" name="address" className="form-control" id="exampleInputEmail1" defaultValue={project.url}
                        ref={url}
                        aria-describedby="emailHelp"/>
                    </div>
                    <div className="mb-3">
                      <label for="exampleInputEmail1" className="form-label">Type</label>
                      <input type="text" name="address" className="form-control" id="exampleInputEmail1" defaultValue={project.type}
                        ref={type}
                        aria-describedby="emailHelp"/>
                    </div>
                    <div className="mb-3">
                      <label for="bannerimage" className="form-label">Banner Image</label>
                      <input type="file" name="bannerimage" className="form-control" id="bannerimage"
                         ref={bannerimage}
                        aria-describedby="emailHelp"/>
                    </div>
                    <button type="submit" className="btn btn-primary">Submit</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        </>
    );
}
export default EditEmployee;