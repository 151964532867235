import React from "react";
import { NavLink } from "react-router-dom";

function Sidebar ({children}){
  const menuItem = [
   
    {
      path:"/dashboard",
      name:"dashboard"
    },
    {
      path:"/profile",
      name:"Profile"
    },
    {
      path:"/project",
      name:"Project"
    },
    {
      path:"/blog",
      name:"Blog"
    },
   

  ]
  const adminMenu = [
    {
      path:"/dashboard",
      name:"dashboard"
    },
    {
      path:"/influencerlist",
      name:"Influencer"
    },
  ]
  const data = JSON.parse(localStorage.getItem('userData'))

    return(
      <>
<aside className="left-sidebar">
      <div>
        <div className="brand-logo d-flex align-items-center justify-content-between">
          {/* <a href="./index.html" className="text-nowrap logo-img"> */}
            <img src={process.env.PUBLIC_URL + 'images/logos/dark-logo.svg'} width="180" alt="" />
          {/* </a> */}
          <div className="close-btn d-xl-none d-block sidebartoggler cursor-pointer" id="sidebarCollapse">
            <i className="ti ti-x fs-8"></i>
          </div>
        </div>
        <nav className="sidebar-nav scroll-sidebar" data-simplebar="">
        <ul id="sidebarnav">

              { data.email=='admin@gmail.com'?
              adminMenu.map((item, index)=>(
                <NavLink to={item.path} key={index} className="link" activeclassName="active">
          <li className="nav-small-cap">
            <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                                <span className="hide-menu">{item.name}
                                </span>
                                </li>
                </NavLink>
              
              ))
              :
                menuItem.map((item, index)=>(
                  <NavLink to={item.path} key={index} className="link" activeclassName="active">
            <li className="nav-small-cap">
              <i className="ti ti-dots nav-small-cap-icon fs-4"></i>
                                  <span className="hide-menu">{item.name}
                                  </span>
                                  </li>
                  </NavLink>
                
                ))
          }
          </ul>
        </nav> 
      </div>

    </aside>
  </>
    );
}

export default Sidebar;
